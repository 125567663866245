<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="people-fill" />
        {{ $t("homeOrg.users") + organization.displayName }}
      </h1>

      <div>
        <b-button class="mr-2" variant="primary" pill @click="$router.go(-1)">
          <b-icon-arrow90deg-left class="mr-2" />
          {{ $t("back") }}
        </b-button>
        <router-link
          v-if="isAdmin"
          :to="`/organization/${this.$route.params.id}/new-user`"
        >
          <b-button variant="primary" data-cy="create-new-user" pill>
            <b-icon-person-plus class="mr-2" />
            {{ $t("users.newUserButton") }}
          </b-button>
        </router-link>
      </div>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-table
        :busy="loadingUsers"
        :current-page="currentPage"
        :fields="fields"
        :head-variant="'light'"
        :items="users"
        :per-page="0"
        class="mb-0"
        hover
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(admin)="data">
          <b-form-checkbox id="checkbox-1" v-model="data.value" disabled />
        </template>

        <template #cell(enabled)="data">
          <b-form-checkbox
            v-model="data.value"
            :disabled="data.item.email === disableDelete"
            switch
            @change="changeUserStatus(data.item, false)"
          />
        </template>

        <template #cell(empty)="data">
          <div class="d-flex justify-content-end">
            <b-button
              v-b-modal.modal-center
              :data-cy="`delete-user-button-${data.item.name.split('/')[1]}`"
              :disabled="data.item.email === disableDelete"
              class="ml-2"
              pill
              size="sm"
              variant="outline-danger"
              @click="deleteModal(data.item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="total > pageSize"
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="total"
        align="right"
        class="mb-0 mt-3"
        data-cy="pagination"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />

      <b-card-text
        v-if="users.length === 0 && !loadingUsers"
        class="text-center my-4"
      >
        {{ $t("users.emptyTable") }}
      </b-card-text>
    </b-card>

    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h4 class="mb-0">
        <b-icon class="mr-2" icon="people-fill" />
        {{ $t("orgs.trustedExternalUsers") }}
      </h4>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-table
        :busy="loadingAssociates"
        :current-page="currentPageAssociates"
        :fields="fieldsTrusted"
        :head-variant="'light'"
        :items="associates"
        :per-page="0"
        class="mb-0"
        hover
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(admin)="data">
          <b-form-checkbox id="checkbox-1" v-model="data.value" disabled />
        </template>

        <template #cell(enabled)="data">
          <b-form-checkbox
            v-model="data.value"
            :disabled="data.item.email === disableDelete"
            switch
            @change="changeUserStatus(data.item, true)"
          />
        </template>

        <template #cell(empty)="">
          <div class="d-flex justify-content-end">
            <b-button
              v-b-modal.modal-center
              class="ml-2"
              disabled
              pill
              size="sm"
              variant="outline-danger"
            >
              <b-icon-trash />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-if="totalAssociates > pageSize"
        v-model="currentPageAssociates"
        :per-page="pageSize"
        :total-rows="totalAssociates"
        align="right"
        class="mb-0 mt-3"
        data-cy="pagination"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />
      <b-card-text
        v-if="associates.length === 0 && !loadingAssociates"
        class="text-center my-4"
      >
        {{ $t("orgs.emptyUsersTable") }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "SingleOrganization",
  data() {
    return {
      users: [],
      associates: [],
      organization: {
        name: "",
        displayName: "",
        id: "",
      },
      pageSize: 10,
      total: null,
      totalAssociates: null,
      currentPage: 1,
      currentPageAssociates: 1,
      fields: [
        { key: "name", label: this.$i18n.t("homeOrg.username") },
        { key: "email", label: this.$i18n.t("email") },
        { key: "admin", label: "Admin" },
        { key: "enabled", label: "Status" },
        { key: "empty", label: "" },
      ],
      fieldsTrusted: [
        { key: "name", label: this.$i18n.t("homeOrg.username") },
        { key: "email", label: this.$i18n.t("email") },
        { key: "admin", label: "Admin" },
        { key: "empty", label: "" },
      ],
      loadingOrg: true,
      loadingUsers: true,
      loadingAssociates: true,
      activeRole: this.$store.getters.activeRole.split("@"),
      disableDelete: this.$store.getters.email,
      isAdmin: false,
    };
  },
  async mounted() {
    await this.orgProvider();
    await this.userProvider(false);
    await this.userProvider(true);
  },
  methods: {
    // get users of org
    async userProvider(associates) {
      associates ? (this.loadingAssociates = true) : (this.loadingUsers = true);
      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          this.$cfg.BASE_IDENTITY_URL +
            `/v0/tenants/${this.$route.params.id}/accounts?associates=${associates}`,
          {
            params: {
              page: associates
                ? this.currentPageAssociates - 1
                : this.currentPage - 1,
              pageSize: this.pageSize,
            },
          }
        );

        // checking if user is an admin
        content.forEach((user) => {
          user.roles?.forEach((role) => {
            if (role.substr(0, role.indexOf("@")) === "admin") {
              user.admin = true;
            }
          });
        });

        if (associates) {
          this.totalAssociates = page.totalElements;
          this.associates = content;
        } else {
          this.total = page.totalElements;
          this.users = content;
        }
      } catch (err) {
        console.log(err);
        if (err.response) console.log(err.response);
      } finally {
        associates
          ? (this.loadingAssociates = false)
          : (this.loadingUsers = false);
      }
    },

    async changeUserStatus(user, associates) {
      associates ? (this.loadingAssociates = true) : (this.loadingUsers = true);
      try {
        const {
          data: { enabled },
        } = await this.$http.patch(
          `${this.$cfg.BASE_IDENTITY_URL}/v0/accounts/${user.id}`,
          { enabled: !user.enabled }
        );

        this.$bvToast.toast(
          `User ${user.name} ${enabled ? "enabled" : "disabled"}!`,
          {
            title: enabled ? "Enabled" : "Disabled",
            toaster: "b-toaster-bottom-right",
            variant: enabled ? "success" : "warning",
            solid: true,
          }
        );
        await this.userProvider(associates);
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    async orgProvider() {
      this.loadingOrg = true;
      try {
        const {
          data: { name, id, displayName },
        } = await this.$http.get(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${this.$route.params.id}`
        );

        // check if user is admin of organization
        if (
          (this.activeRole[0] === "admin" && this.activeRole[1] === name) ||
          this.activeRole[0] === "system.admin"
        ) {
          this.isAdmin = true;
          this.fields.push({ key: "empty", label: "" });
        }
        this.organization.name = name;
        this.organization.id = id;
        this.organization.displayName = displayName;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loadingOrg = false;
      }
    },

    // navigate to edit user page
    goToEditUser(userName) {
      this.$router.push(
        `/organization/${this.$route.params.id}/user/${userName}`
      );
    },

    // delete a user
    async deleteUser(id) {
      try {
        await this.$http.delete(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/accounts/${id}`
        );
        this.$bvToast.toast("User deleted!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        this.userProvider();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    deleteModal(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Are you sure that you want to delete this user?", {
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteUser(id);
        })
        .catch((err) => {
          if (err.response) console.log(err.response);
          // An error occurred
        });
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.userProvider(false);
      },
    },
    currentPageAssociates: {
      handler: function () {
        this.userProvider(true);
      },
    },
  },
};
</script>
